import React from "react"

import Layout from "components/layout"
import SEO from "components/seo"

import CenterHeader from "components/center-group/center-header/CenterHeader"
import OneTimeMessageBlock from "components/center-group/one-time-message/OneTimeMessageBlock"
import OneTimeMessageModal from "components/center-group/one-time-message/OneTimeMessageModal"
import CenterEvents from "components/center-group/center-events/CenterEvents"
import CenterPresentation from "components/center-group/center-presentation/CenterPresentation"
import CenterOffers from "components/center-group/center-offers/CenterOffers"
import SimplePushBlock from "components/home-group/simple-push-block/SimplePushBlock"
import ComplexPushBlock from "components/home-group/complex-push-block/ComplexPushBlock"
import Reinsurance from "components/home-group/reinsurance/Reinsurance"

const LyonCenterPage = (props) => {
  return (
    <Layout location={props.location}>
      <SEO title="Center Lyon" />
      <OneTimeMessageModal />
      <CenterHeader selectedCenter="lyon" />
      <OneTimeMessageBlock />
      <CenterEvents />
      <CenterPresentation />
      <CenterOffers />
      <SimplePushBlock />
      <ComplexPushBlock />
      <Reinsurance />
    </Layout>
  )
}

export default LyonCenterPage;
